html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #e3e3e3;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
  /* margin-top: 40px; */
}

#content {
  padding: 30px 5px;
  min-height: 100%;
  width: 100%;
}

.table-sm p {
  margin: 0;
}

.edit {
  cursor: pointer;
  border-bottom: 1px transparent;
}

.edit:hover {
  border-bottom: 1px dashed blue;
}

.table th,
.table td {
  padding: .1rem .4rem;
  font-size: 0.75rem;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  max-width: 200px;
}

.table .thead-dark th {
  white-space: nowrap;
  cursor: pointer;
  background-color: #343a40;
  border: none;
  border-bottom: .05rem solid #454d55;
  color: rgb(226, 226, 226);
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-hot>td {
  background-color: rgb(245, 131, 131);
  border-bottom: 1px solid #f77561;
  color: #000;
}

.table-warning>td {
  background-color: rgb(244, 211, 173);
  border-bottom: 1px solid #a78681;
  color: #000;
}

.table-info>td {
  background-color: rgb(215, 208, 223);
  border-bottom: 1px solid #86799b;
  color: #000;
}

.table-success>td {
  background-color: rgb(208, 223, 211);
  border-bottom: 1px solid #8cc09e;
  color: #000;
}

table tr.mem>td {
  background-color: rgb(151, 242, 162);
}

.pagination {
  margin: 0;
  padding: 0;
}

.header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 850px;
  height: 40px;
  padding: 0;
  background: #2a2730;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 2000;
  /* overflow-x: scroll; */
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  line-height: 40px;
  font-weight: 100;
  color: #e48244;
}

.header nav {
  float: left;
  width: 100%;
  font-size: 100%;
  background: #2a2730;
}

.header nav a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 9px;
  min-width: 35px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #dfcfcf;
  will-change: background-color;
}
.header nav a:hover {
  background: #555;
  color: #fff;
}

.header nav a:active,
.header nav a.active {
  background: #000;
  color: #e48244;
  /* color: #000; */
  /* rgba(0,0,0,0.2); */
}

label {
  font-size: 0.62rem;
  font-weight: 400;
  opacity: 0.9;
  white-space: nowrap;
}

pre {
  font-family: monospace;
  font-size: 80%;
  font-weight: 500;
}
pre.white {
  background-color: rgb(254, 254, 254);
  padding: 5px;
  color: rgb(0, 0, 0);
}

.form-checkbox {
  margin: 0 0 0 0.3rem;
  padding: .1rem .4rem .1rem .7rem;
}

.toast-absolute {
  position: absolute;
  top: 3em;
  right: 1.5em;
  width: auto;
  height: auto;
  z-index: 120;
  cursor: pointer;
}

.modal-title {
  margin-top: -0.3rem;
}
.modal.modal-sm .modal-container {
  padding: 0;
}
.modal .modal-container .modal-body {
  padding: 0.2rem 0.4rem;
}
.modal .modal-container .modal-header {
  background: #d0d0e0;
  padding: 0.3rem 0.8rem 0rem 0.5rem;
}

.tooltip {
  z-index: 100;
}

/* direct */
.direct-true {
  background-color: #a10063;
  color: #fff;
}

.direct-false {
  background-color: transparent;
  color: transparent;
}

.direct-false:hover {
  background-color: #a100636e;
  color: #000;
}

/* num */
.num-true {
  background-color: #5678c0;
  color: #fff;
}

.num-false {
  background-color: transparent;
  color: transparent;
}

.num-false:hover {
  background-color: #1e3f5f86;
  color: #FFF;
}

.chip {
  height: 1.1rem;
}

td a,
td a.active,
td a:active,
td a:focus,
td a:hover {
  color: #0000ad !important;
}

.nowrap {
  white-space: nowrap;
}

.btn-sm { font-size: .65rem }

.bg_my { background-color: rgb(39, 39, 39) !important; color: #fff; }
.bg_buy { background-color: rgb(3, 133, 3) !important; color: #fff; }
.bg_sell { background-color: rgb(180, 0, 0) !important; color: #fff; }
.bg_broker { background-color: rgb(51, 39, 15) !important; color: #fff; }
.bg_lease { background-color: rgb(125, 17, 158) !important; color: #fff; }
.bg_interest { background-color: rgb(3, 114, 114) !important; color: #fff; }
.bg_domain { background-color: rgb(3, 3, 200) !important; color: #fff; }

.btn_my { border: 1px solid #333; color: #000; }
.btn_buy { border: 1px solid rgb(3, 133, 3) !important; color: #005500; }
.btn_sell { border: 1px solid rgb(180, 0, 0) !important; color: #550000; }
.btn_broker { border: 1px solid rgb(51, 39, 15) !important; color: #552211; }
.btn_lease { border: 1px solid rgb(125, 17, 158) !important; color: #550055; }
.btn_interest { border: 1px solid rgb(3, 114, 114) !important; color: #005555; }
.btn_domain { border: 1px solid rgb(100, 100, 200) !important; color: #333355; }

@keyframes spinner {
  0% { transform: translate3d(-50%, -50%, 0) rotate(0deg); }
  100% { transform: translate3d(-50%, -50%, 0) rotate(360deg); }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 10px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 80px;
  width: 80px;
  position: absolute;
  top: 40vh;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
.spin-min {
  display: block;
  width: 20px;
  height: 20px;
}

.spin-min::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 4px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.loader {
  background: #00000077;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  text-align: center;
  z-index: 110;
  display: none;
}

.loader.show {
  transition: opacity 0.5s;
  opacity: 1
}

pre.code {
  margin: 0;
  font-size: 0.65em;
  text-shadow: 0 0 1px #c1c1c1;
}

.btn-file {
  position: relative;
}
.btn-file input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.memo {
  width: 100%;
  height: calc(100% - 43px);
  border: 0;
  padding: 0.5em;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
}
